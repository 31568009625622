export type MetricDescriptionType = {
  name: string
  description?: string
}

export type MetricType =
  | "standardScoring"
  | "customScoring"
  | "roundModifiers"
  | "tiebreaker"
  | "entriesPerUser"
  | "slogan"
  | "security"
  | "avatar"
  | "additionalRules"
  | "managers"
  | "picksByWeek"
  | "poolsByParticipants"
  | "poolsByParticipantsWithPicks"

export const Metrics: Record<MetricType, MetricDescriptionType> = {
  standardScoring: {
    name: "Standard Scoring",
    description: "Shows a description of how many pools used the standard scoring system",
  },
  customScoring: {
    name: "Custom Scoring",
    description: "Shows a description of how many pool customized the standard scoring system",
  },
  roundModifiers: {
    name: "Round Modifiers",
    description: "Shows how many pools used round bonuses as part of the scoring system",
  },
  tiebreaker: {
    name: "Tiebreakers",
    description: "Shows how many pools used tiebreakers",
  },
  entriesPerUser: {
    name: "Max Entries Per User",
    description: "Shows a breakdown of the Max Entries per User by pool",
  },
  slogan: {
    name: "Slogan",
    description: "Shows how many pools use slogans and how many do not",
  },
  security: {
    name: "Security",
    description: "Shows a breakdown of how many pools use password vs magic links",
  },
  avatar: {
    name: "Avatar",
    description: "Shows a breakdown of how many pools use custom avatars vs default ones",
  },
  additionalRules: {
    name: "Additional Rules",
    description: "Shows a breakdown of how many pools have additional rules",
  },
  managers: {
    name: "Managers per Pool",
    description: "Shows a breakdown of how many managers there are per pool",
  },
  picksByWeek: {
    name: "Picks by Week",
    description: "Show the unique number of entries that made at least a pick, broken down by week",
  },
  poolsByParticipants: {
    name: "Pools by Participants",
    description: "Show a breakdown of pools broken out by the number of participants",
  },
  poolsByParticipantsWithPicks: {
    name: "Pools by Participants w/ picks",
    description: "Show a breakdown of pools broken by the number of participants and how many participants made picks",
  },
}
