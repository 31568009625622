import * as NCAABQueries from "./NcaabQueries"
import * as NFLQueries from "./NFLQueries"
import * as NCAAFQueries from "./NCAAFQueries"
import { MetricType } from "./MetricType"

type QueryReader = string | ((uid: string) => string)
type QueryByMetric = Record<MetricType, QueryReader>
type MetricByGame = Record<string, Partial<QueryByMetric>>

const metricsByGame: MetricByGame = {
  "cbs-ncaab-tournament-manager": {
    standardScoring: NCAABQueries.standardScoring,
    customScoring: NCAABQueries.customScoring,
    roundModifiers: NCAABQueries.roundModifiers,
    tiebreaker: NCAABQueries.tiebreakers,
    entriesPerUser: NCAABQueries.entriesPerUser,
    slogan: NCAABQueries.slogan,
    security: NCAABQueries.security,
    avatar: NCAABQueries.avatar,
    additionalRules: NCAABQueries.additionalRules,
    managers: NCAABQueries.managers,
    poolsByParticipants: NCAABQueries.poolsByParticipants,
    poolsByParticipantsWithPicks: NCAABQueries.poolsByParticipantsWithPicks,
  },
  "cbs-ncaaf-pickem-manager": {
    picksByWeek: NCAAFQueries.managerPicksByWeek,
  },
  "cbs-ncaaf-pickem-challenge": {
    picksByWeek: NCAAFQueries.challengePicksByWeek,
  },
  "cbs-nfl-pickem-challenge": {
    picksByWeek: NFLQueries.picksByWeek,
  },
}

function listMetricsForGame(uid: string) {
  const metrics = metricsByGame[uid]
  if (!metrics) {
    return []
  }
  return Object.keys(metrics)
}

function getStatementForGame(uid: string, metric?: MetricType) {
  if (!metric) {
    return null
  }

  const statement = metricsByGame[uid]?.[metric]
  if (!statement) {
    return null
  }
  if (typeof statement === "function") {
    return statement(uid)
  }
  return statement
}

export { listMetricsForGame, getStatementForGame }
