import React, { FC } from "react"
import { AdminSQLQuery } from "../../../__generated__/AdminSQLQuery"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableContainer from "@material-ui/core/TableContainer"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

interface IMetricTableProps {
  data?: AdminSQLQuery["sql"]
}

const MetricTable: FC<IMetricTableProps> = ({ data }) => {
  const row0 = data?.[0]

  if (!row0) {
    return null
  }

  const rowHeaders = Object.keys(row0)
  const tableHeadCells = rowHeaders.map((header, i) => <TableCell key={`${i}_${header}`}>{header}</TableCell>)
  const tableBodyCells = data?.map((item, i) => {
    const rowCells = rowHeaders.map((header, j) => <TableCell key={`${header}:${i}:${j}`}>{item[header] ?? "-"}</TableCell>)
    return <TableRow key={i}>{rowCells}</TableRow>
  })

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>{tableHeadCells}</TableRow>
        </TableHead>
        <TableBody>{tableBodyCells}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default MetricTable
