export const standardScoring = (uid: string) =>
  `SELECT count(1) 'Standard Scoring Pools' 
    FROM Pool 
    WHERE gameInstanceUid = '${uid}' AND (poolSettings IS NULL OR JSON_EXTRACT(poolSettings, '$.roundBonuses') = JSON_ARRAY(1,2,4,8,16,32))`

export const customScoring = (uid: string) =>
  `SELECT count(1) 'Standard Scoring Pools' 
    FROM Pool 
    WHERE gameInstanceUid = '${uid}' AND (poolSettings IS NOT NULL AND JSON_EXTRACT(poolSettings, '$.roundBonuses') <> JSON_ARRAY(1,2,4,8,16,32))`

export const roundModifiers = (
  uid: string,
) => `select 'Round + Seed' as Modifier, count(1) as 'Pool Count' from Pool WHERE gameInstanceUid = '${uid}' AND poolSettings IS NOT NULL AND JSON_CONTAINS(poolSettings, '"ADD_SEED"', '$.roundModifiers')
    UNION
    select 'Round x Seed' as Modifier, count(1) as 'Pool Count' from Pool WHERE gameInstanceUid = '${uid}' AND poolSettings IS NOT NULL AND JSON_CONTAINS(poolSettings, '"MULTIPLY_SEED"', '$.roundModifiers');`

export const tiebreakers = (
  uid: string,
) => `SELECT COALESCE(Pool.poolSettings->>'$.mainTiebreaker', Segment.poolSettings->>'$.mainTiebreaker') as Tiebreaker, count(1) as "Pool Count"
    FROM Season INNER JOIN Pool ON Season.id = Pool.seasonId
    INNER JOIN Segment ON Season.id = Segment.seasonId
    WHERE Pool.gameInstanceUid = '${uid}'
    GROUP BY COALESCE(Pool.poolSettings->>'$.mainTiebreaker', Segment.poolSettings->>'$.mainTiebreaker')
    ORDER BY count(1) DESC`

export const entriesPerUser = (
  uid: string,
) => `SELECT COALESCE(Pool.poolSettings->>'$.maxEntriesPerUser', Segment.poolSettings->>'$.maxEntriesPerUser') as 'Entries Per User', count(1) as 'Pool Count'
    FROM Season INNER JOIN Pool ON Season.id = Pool.seasonId
    INNER JOIN Segment ON Season.id = Segment.seasonId
    WHERE Pool.gameInstanceUid = '${uid}'
    GROUP BY COALESCE(Pool.poolSettings->>'$.maxEntriesPerUser', Segment.poolSettings->>'$.maxEntriesPerUser')
    ORDER BY COALESCE(Pool.poolSettings->>'$.maxEntriesPerUser', Segment.poolSettings->>'$.maxEntriesPerUser')`

export const slogan = (
  uid: string,
) => `select IF(slogan IS NULL OR slogan = '', "Do NOT use slogan", "Use slogan") as Slogan, count(1) as 'Pool Count' from Pool 
WHERE Pool.gameInstanceUid = '${uid}'
GROUP BY IF(slogan IS NULL OR slogan = '', "Do NOT use slogan", "Use slogan")`

export const avatar = (
  uid: string,
) => `select IF(avatarUrl IS NULL OR avatarUrl = '', "Use Default Avatar", "Use Custom Avatar") as Avatar, count(1) as 'Pool Count' from Pool 
WHERE Pool.gameInstanceUid = '${uid}'
GROUP BY IF(avatarUrl IS NULL OR avatarUrl = '', "Use Default Avatar", "Use Custom Avatar")`

export const additionalRules = (
  uid: string,
) => `select IF(constitution IS NULL OR constitution = '', "Do NOT have additional rules", "Have additional rules") as 'Additional Rules', count(1) as 'Pool Count' from Pool 
WHERE Pool.gameInstanceUid = '${uid}'
GROUP BY IF(constitution IS NULL OR constitution = '', "Do NOT have additional rules", "Have additional rules")`

export const security = (uid: string) => `select IF(usesMagicLink, "Use Magic Link", "Use Password") as Security, count(1) as 'Pool Count' from Pool 
WHERE Pool.gameInstanceUid = '${uid}'
GROUP BY usesMagicLink`

export const managers = (uid: string) =>
  `SELECT managers AS 'Managers', count(poolId) as 'Pool Count' 
    FROM (SELECT Pool.id as poolId, COUNT(Member.userId) as managers 
      FROM Pool INNER JOIN Member ON Pool.id = Member.poolId 
      WHERE Pool.gameInstanceUid = '${uid}' AND Member.roles = JSON_ARRAY('MANAGER') GROUP BY Pool.id) T 
    GROUP BY T.managers 
    ORDER BY managers;`

export const poolsByParticipants = (uid: string) => `SELECT T.count AS 'Pool Count', T.entriesCount AS 'Entries Count' 
  FROM (
    SELECT count(1) AS 'count', entriesCount   FROM Pool  WHERE gameInstanceUid = '${uid}'  GROUP BY entriesCount
  ) T
  WHERE T.entriesCount > 0
  ORDER BY T.entriesCount`

export const poolsByParticipantsWithPicks = (
  uid: string,
) => `SELECT T.EntriesCount AS 'In pools of with entries', sum(T.entriesWithPicks) AS 'made picks'
FROM(
SELECT Pool.entriesCount, count(1) AS 'entriesWithPicks' FROM Pool
	INNER JOIN Entry ON Entry.poolId = Pool.Id
	WHERE Pool.gameInstanceUid = '${uid}'
	GROUP BY Entry.poolId
	) T
WHERE T.entriesCount > 0
GROUP BY T.entriesCount
ORDER BY T.entriesCount;`
