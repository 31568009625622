export type GameType = {
  uid: string
  name: string
}
export type GameTypes = Array<GameType>

export const Games: GameTypes = [
  { uid: "cbs-ncaab-tournament-manager", name: "NCAAB Tournament Manager" },
  { uid: "cbs-ncaab-tournament-challenge", name: "NCAAB Tournament Challenge" },
  { uid: "cbs-ncaaf-pickem-manager", name: "NCAAF Office Pool Manager" },
  { uid: "cbs-ncaaf-pickem-challenge", name: "NCAAF Office Pool Challenge" },
  { uid: "cbs-nfl-pickem-challenge", name: "NFL Office Pool Challenge" },
]
