import React, { useState, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from "@material-ui/core/CircularProgress"
import Layout from "../components/Layout"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { ADMIN_SQL_QUERY } from "../queries"
import { Games, GameType } from "./GameType"
import { Metrics, MetricType } from "./MetricType"
import { listMetricsForGame, getStatementForGame } from "./MetricByGame"
import Message from "./Message"
import MetricTable from "./MetricTable"

const NO_DATA_MESSAGE = "No data found."
const ERROR_MESSAGE = "Could not access the data at this moment. Something went wrong."

const Analytics = () => {
  const [selectedGameUid, setSelectedGameUid] = useState<GameType["uid"]>(() => Games[0].uid)
  const [selectedMetricId, setSelectedMetricId] = useState<MetricType | undefined>(undefined)
  const [query, { called, data, loading, error }] = useLazyQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {})
  const statement = getStatementForGame(selectedGameUid, selectedMetricId)

  const hasError = !loading && !!error
  const recordsFound = !!data?.sql?.length
  const hasNoData = called && !hasError && !loading && selectedMetricId && !recordsFound
  const hasData = called && !hasError && !loading && selectedMetricId && recordsFound

  useEffect(() => {
    if (!statement) {
      return
    }
    query({ variables: { statement } })
  }, [statement, selectedGameUid, query])

  const handleGameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedGameUid(String(e.target.value))
    setSelectedMetricId(undefined)
  }

  const handleMetricChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = !!e.target.value ? (e.target.value as MetricType) : undefined
    setSelectedMetricId(newValue)
  }

  const gameOptions = Games.map((game) => (
    <MenuItem key={game.uid} value={game.uid}>
      {game.name}
    </MenuItem>
  ))
  const gameMenu = (
    <FormControl fullWidth>
      <InputLabel id="game-select-label">Game</InputLabel>
      <Select labelId="game-select-label" id="game-select" value={selectedGameUid} onChange={handleGameChange}>
        {gameOptions}
      </Select>
    </FormControl>
  )

  const availableMetrics = listMetricsForGame(selectedGameUid)
  const isMetricsEmpty = !availableMetrics.length
  const metricOptions = availableMetrics.map((metricKey) => {
    const metric = Metrics[metricKey]

    return (
      <MenuItem key={metricKey} value={metricKey} dense>
        <ListItemText primary={metric.name} secondary={metric.description} />
      </MenuItem>
    )
  })
  const metricMenu = (
    <FormControl fullWidth>
      <InputLabel id="metric-select-label">Metric</InputLabel>
      <Select labelId="metric-select-label" id="metric-select" value={selectedMetricId || ""} onChange={handleMetricChange} disabled={isMetricsEmpty}>
        {metricOptions}
      </Select>
      {isMetricsEmpty && <FormHelperText>{`No available metrics for the selected game: ${selectedGameUid}`}</FormHelperText>}
    </FormControl>
  )

  return (
    <Layout title="Pool Data">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {gameMenu}
        </Grid>
        <Grid item xs={12}>
          {metricMenu}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Message variant="DEFAULT">
              <CircularProgress />
            </Message>
          )}
          {hasError && <Message variant="ERROR">{ERROR_MESSAGE}</Message>}
          {hasNoData && <Message variant="DEFAULT">{NO_DATA_MESSAGE}</Message>}
          {hasData && <MetricTable data={data?.sql} />}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Analytics
